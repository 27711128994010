import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["singlespeed, dam", "singlespeed, herr", "singlespeed"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "singlespeeds"
    }}>{`Singlespeeds`}</h1>
    <p>{`En singlespeed cykel är en cykel som helt saknar växlar. Detta gör givetvis att du inte kan växla vilket gör att den kan bli väldigt trög att cykla i i uppförsbackar samt gör att du måste trampa väldigt fort vid höga hastigheter.`}</p>
    <h2 {...{
      "id": "varför-singlespeed"
    }}>{`Varför singlespeed?`}</h2>
    <p>{`Varför vill man du ha en singlespeed? Avsaknaden av växlar låter ju bara som en nackdel, eller?`}</p>
    <h3 {...{
      "id": "enkelhet"
    }}>{`Enkelhet`}</h3>
    <p>{`Avsaknaden av växlar gör att du helt enkelt inte behöver bry dig om att växla och kan därmed fokusera på allat annat. Många som har växlar använder ändå inte dessa optimalt samt att de flesta inte ens utnyttjar de växlar som de har. Växlar och alla kuggar som är inblandade gör även att det blir mer friktion i kedjan och kraföverföringen. En singlespeed blir därför något mer enkelcyklad.`}</p>
    <h3 {...{
      "id": "mindre-service"
    }}>{`Mindre service`}</h3>
    <p>{`Växlar är en av de vanligaste felkällorna på en cykel, tillsammans med bromsar. Genom att plocka bort växlarna har du helt enkelt en sak mindre att behöva laga. Kvar att tänka på blir endast däcktryck, kedjespänning och bromsjustering. Detta gör att cykeln blir billigare att äga då du inte behöver lämna in den på service lika ofta eller behöver köpa nya delar lika ofta.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      